<template>
  <div>
    <b-modal @hidden="resetModal"
       ref="modal-preview" centered size="lg"  content-class="overflow-hidden">
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark">
          <i class="uil uil-file-graph text-primary"></i> Factoring Application
        </h5>
      </template>
     
      <div class="d-flex justify-content-lg-center overflow-auto bg-light mt-0 p-3 rounded">
        <div class="bg-white p-3 shadow">
        <div id="nodeToRenderAsPDF" class="printWrapper" v-if="account_type">
          <div class="printSection">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12"><div class="bg-dark text-center text-white text-uppercase font-size-14 fw-bold">Executive Summary</div></div>
              <div class="col-auto ms-auto">
                <div class="bg-light text-center text-dark text-uppercase px-2 py-1 fw-semibold font-size-10">Document A</div>
              </div>
            </div>
            <div class="row" v-if="exportData.forms.applicant">
            <div class="col-6 my-2">
              <div class="d-flex align-items-center">
                <label class="me-1 mb-0">Applicant:</label>
                <div class="w-100 fw-semibold" v-if="forms.applicant">{{ forms.applicant.company_name}}</div>
              </div>
            </div>
            <div class="col my-2">
              <div class="d-flex align-items-center">
                <label class="me-1 mb-0">Originator:</label>
                <div class="w-100 fw-semibold" v-if="forms.originator">{{ forms.originator.name || ''}}</div>
              </div>
            </div>
            <div class="col-auto my-2">
              <div class="d-flex align-items-center">
                <label class="me-1 mb-0">Lead:</label>
                <div class="w-100 fw-semibold">{{ forms.lead}}</div>
              </div>
            </div>
            <div class="col-12">
              <div class="sectionPart">Part A: Current and Proposed Facilities </div>
            </div>
            <div class="col-12">
              <table class="border border-dark text-center align-middle customTable">
                <thead class="fw-medium align-middle text-center">
                  <tr>
                    <th colspan="3"><span v-if="forms.pre_factoring_purpose_type=='Term Advance'">{{forms.pre_factoring_purpose_type}}</span> Pre-Factoring</th>
                    <th colspan="3" class="border-start border-dark">Factoring</th>
                    <th rowspan="2" style="border:2px solid #222;border-bottom:1px solid #222">Maximum<br>Exposure</th>
                  </tr>
                  <tr>
                    <th class="border-top border-bottom border-end">Current</th>
                    <th class="border-top border-bottom">Proposed</th>
                    <th style="border:2px solid #222; border-bottom:1px solid #222">Total</th>
                    <th class="border-top border-bottom border-end">Current</th>
                    <th class="border-top border-bottom">Proposed</th>
                    <th style="border:2px solid #222; border-bottom:1px solid #222; border-right: 0px;">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pb-2 border-end">
                      <span class="d-block">{{forms.currency}}</span>
                        {{convertCurrencyFormat(forms.term_advance_pre_factoring.current, false) }}
                    </td>
                    <td class="pb-2">
                      <span class="d-block">{{forms.currency}}</span>
                      {{convertCurrencyFormat(forms.term_advance_pre_factoring.proposed, false) }}
                    </td>
                    <td style="border:2px solid #222; border-top: 0px;" class="pb-2 ">
                      <span class="d-block">{{forms.currency}}</span>
                      <div v-if="forms.term_advance_pre_factoring.total">
                        {{convertCurrencyFormat(forms.term_advance_pre_factoring.total || 0, false) }}
                      </div>
                      <div v-else>0</div>
                    </td>
                    <td class="pb-2 border-end">
                    <span class="d-block">{{forms.currency}}</span>
                      <div v-if="forms.factoring.total">
                        {{convertCurrencyFormat(forms.factoring.current, false) }}
                      </div>
                    </td>
                    <td class="pb-2">
                      <span class="d-block">{{forms.currency}}</span>
                      {{convertCurrencyFormat(forms.factoring.proposed, false) }}
                    </td>
                    <td style="border:2px solid #222; border-top: 0px;border-right: 0px;" class="pb-2">
                      <span class="d-block">{{forms.currency}}</span>
                      <div v-if="forms.factoring.total">
                        {{convertCurrencyFormat(forms.factoring.total, false) }}
                      </div>
                    </td>
                    <td style="border:2px solid #222; border-top: 0px;" class="pb-2">
                      <span class="d-block">{{forms.currency}}</span>
                      {{convertCurrencyFormat(forms.maximum_exposure, false) }}
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12">
              <div class="sectionPart">Part B: Contract Details</div>
            </div>
            <div class="col-12">
              <table class="border border-dark align-middle text-center customTable2">
                <thead class="fw-medium align-middle text-center">
                  <tr>
                    <th>Title</th>
                    <th>Awarder</th>
                    <th class="text-nowrap">Nature of Contract</th>
                    <th>Amount</th>
                    <th>Tenure</th>
                    <th>Mode</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-wrap text-start px-2 py-1 align-top" width="37%">
                    {{forms.contract_title}}
                    </td>
                    <td class="text-wrap text-start px-2 py-1 align-top" >
                      {{forms.contract_awarder}}
                    </td>
                    <td class="text-center px-2 py-1 align-top" >
                      {{forms.nature_of_contract.label}}
                    </td>
                  
                    <td class="px-2">
                      <span class="d-block">{{forms.currency}}</span>
                      {{convertCurrencyFormat(forms.contract_amount, false) }}
                    </td>
                    <td class="text-wrap px-2">
                      <div>Month</div>
                      <div>{{forms.contract_tenure}}</div>
                    </td>
                    <td class="fw-semibold">
                    {{forms.payment_mode.value}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12">
              <div class="sectionPart">Part C: Forecast Advance and Collection Timeline</div>
            </div>
            <div class="col-12" v-if="forms.nature_of_contract.value=='milestone_staggered'">
              <div class="table-responsive text-nowrap text-center">
                <table
                  class="border text-nowrap border-dark align-top text-center customTable"
                >
                  <thead
                    class="fw-medium font-size-14 align-middle text-center"
                  >
                    <tr>
                      <th :colspan="`${parseFloat(2*forms.collection_forecast_milestoneStaggered.length)}`" class="border-bottom">Tenure ({{forms.collection_forecast_custom_tenure_type}})</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="font-size-14">
                      <td
                        v-for="(value, index) in forms.collection_forecast_milestoneStaggered" :key="index"
                        colspan="2"
                        class="border-end border-bottom"
                        :width="`${parseFloat(100/forms.collection_forecast_milestoneStaggered.length)}%`"
                      >
                      <span v-for="(v, i) in value.months" :key="'cf'+i + i"> {{ v.name }} 
                          <span v-if="!(forms.collection_forecast_milestoneStaggered[0]
                          .months.length-1)==i">-</span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td :colspan="2" v-for="(value, index) in forms.collection_forecast_milestoneStaggered" :key="index" class="border-end">
                        <div
                        class="position-relative pb-1"
                        v-for="(v, i) in value.months"
                        :key="'cfw' + i"
                        width="50%"
                        :class="{ 'text-start': i === 0 , 'text-end pt-1': i === 1  }"
                      >
                        <div class="w-100"> 
                          <div v-if="v.remark" :class="{'pt-1 d-block': i == 1 }">
                            <span class="topArrow"></span>
                            <div
                              class="position-absolute px-1"
                              :class="{
                                'end-0': i == 1,
                                'start-0': i == 0,
                              }"
                            >
                              <span
                                v-html="v.remark.replaceAll('\n', '<br>')"
                              ></span>

                            </div>
                          </div>
                        </div>
                        
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        v-for="(value, index) in forms.collection_forecast_milestoneStaggered" :key="index"
                        colspan="2"
                        class="border-top-0 border-end pb-1"
                        height="25"
                      >
                        <span
                          v-html="
                            value.general_remark
                          "
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td :colspan="`${parseFloat(2*forms.collection_forecast_milestoneStaggered.length)}`" class="border-top">
                        Average
                        <b
                          >{{
                              forms.average_collection_forecast.label
                          }}-Days</b
                        >
                        Collection Forecast
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12" v-else-if="forms.nature_of_contract.value=='one_off'">
                <table class="border text-nowrap border-dark align-middle text-center customTable">
                <thead class="fw-medium font-size-14 align-middle text-center">
                  <tr>
                    <th :colspan="`${parseFloat(2*forms.collection_forecast_one_off.length)}`" class="border-bottom">Month</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colspan="2"
                      v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                      class="border-end border-bottom"
                      :width="`${parseFloat(100/forms.collection_forecast_one_off.length)}`"
                    >
                      {{ value.name }}
                    </td>
                  </tr>
                  <tr class="font-size-14">
                    <td
                      colspan="2"
                      v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                      class="border-end border-bottom"
                      :width="`${parseFloat(100/forms.collection_forecast_one_off.length)}%`"
                    >
                      <div class="d-flex">
                        <div 
                          v-for="(v, i) in value.weeks"
                          :key="'cf'+ index + i"
                          class="text-start w-100"
                          :class="{ 'text-end': i == 1 }"
                        >
                          {{ v.name }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                      class="border-end position-relative"
                      :width="`${parseFloat(100/forms.collection_forecast_one_off.length)}`"
                    >
                      <div class="d-flex gap-2 w-100">
                        <div 
                          v-for="(v, i) in value.weeks"
                          :key="'cfw'+ index + i"
                          class="text-start w-100"
                          :width="`${parseFloat(100/value.weeks.length)}%`"
                          :class="{ 'text-end': i == 1 }"
                        >
                        <span v-if="v.remark">
                          <span
                            class="topArrow"
                            :class="{ 'text-start': i == 0,'text-end': i == 1 }"
                          ></span>
                          <div
                            class="position-absolute px-2"
                            :class="{
                              'end-0 text-end': i == 1,
                              'start-0 text-start': i == 0,
                            }"
                          >
                            <span
                              v-html="v.remark.replaceAll('\n', '<br>')"
                            ></span>

                          </div>
                        </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      v-for="(value,index) in forms.collection_forecast_one_off" :key="index"
                      height="22"
                      class="border-top-0 border-end"
                    >
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      v-for="(value, index) in forms.collection_forecast_one_off" :key="index"
                      class="border-top-0 border-end pb-3"
                      height="30"
                    >
                      <span
                        v-html="value.general_remark"
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12" v-else>
              <table class="border text-nowrap border-dark align-middle text-center customTable">
                <thead class="fw-medium align-middle text-center">
                  <tr>
                    <th colspan="20" class="border-bottom">Month</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td colspan="4" v-for="(value,index) in forms.collection_forecast" :key="index" class="border-end border-bottom" width="20%">{{value.name}}
                    </td>
                </tr>
                <tr>
                    <td v-for="(v,i) in forms.collection_forecast[0].weeks" :key="'cf1'+i" class="border-end border-bottom" width="5%">{{v.name}}</td>
                    <td v-for="(v,i) in forms.collection_forecast[1].weeks" :key="'cf2'+i" class="border-end border-bottom" width="5%">{{v.name}}</td>
                    <td v-for="(v,i) in forms.collection_forecast[2].weeks" :key="'cf3'+i" class="border-end border-bottom" width="5%">{{v.name}}</td>
                    <td v-for="(v,i) in forms.collection_forecast[3].weeks" :key="'cf4'+i" class="border-end border-bottom" width="5%">{{v.name}}</td>
                    <td v-for="(v,i) in forms.collection_forecast[4].weeks" :key="'cf5'+i" class="border-end border-bottom" width="5%">{{v.name}}</td>
                </tr>
                <tr>
                    <td class="position-relative" v-for="(v,i) in forms.collection_forecast[0].weeks" :key="'cfw'+i" :class="{ 'border-end': i==3}">
                      <span v-if="v.remark">
                        <span class="topArrow"></span>
                        <div class="position-absolute px-1" :class="{'end-0': (i==3 || i==2), 'start-0': (i==0 || i==1)}">
                          <span v-html="v.remark.replaceAll('\n','<br>')" ></span>
                          
                          <div v-if="forms.term_advance_pre_factoring.proposed">
                          RM {{convertCurrencyFormat(forms.term_advance_pre_factoring.proposed, false) }}
                          </div>
                        </div>
                        
                      </span>
                    </td>
                    <td class="border-none position-relative" v-for="(v,i) in forms.collection_forecast[1].weeks" :key="'cfw2'+i" :class="{ 'border-end': i==3}">
                      <span v-if="v.remark">
                        <span class="topArrow"></span>
                        <span v-html="v.remark.replaceAll('\n','<br>')"  class="position-absolute px-2" :class="{'end-0': (i==3 || i==2), 'start-0': (i==0 || i==1)}"></span>
                        
                      </span>
                    </td>
                    <td class="border-none position-relative" v-for="(v,i) in forms.collection_forecast[2].weeks" :key="'cfw3'+i" 
                      :class="{ 'border-end': i==3}"> 
                      <span v-if="v.remark">
                        <span class="topArrow"></span>
                        <span v-html="v.remark.replaceAll('\n','<br>')"  class="position-absolute px-2" :class="{'end-0': (i==3 || i==2), 'start-0': (i==0 || i==1)}"></span>
                      </span>
                    </td>
                    <td class="border-none position-relative" v-for="(v,i) in forms.collection_forecast[3].weeks" :key="'cfw4'+i" :class="{ 'border-end': i==3}"> 
                      <span v-if="v.remark">
                        <span class="topArrow"></span>
                        <span v-html="v.remark.replaceAll('\n','<br>')" class="position-absolute px-2" :class="{'end-0': (i==3 || i==2), 'start-0': (i==0 || i==1)}"></span>
                      </span>
                    </td>
                    <td class="border-none position-relative" v-for="(v,i) in forms.collection_forecast[4].weeks" :key="'cfw5'+i" :class="{ 'border-end': i==3}"> 
                      <span v-if="v.remark" >
                        <span class="topArrow"></span>
                        <span v-html="v.remark.replaceAll('\n','<br>')" class="position-absolute px-2" :class="{'end-0': (i==3 || i==2), 'start-0': (i==0 || i==1)}"></span>
                      </span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="border-top-0 border-end pb-3" height="42"><span v-html="forms.collection_forecast[0].general_remark"></span></td>
                    <td colspan="4" class="border-top-0 border-end pb-3"><span v-html="forms.collection_forecast[1].general_remark"></span></td>
                    <td colspan="4" class="border-top-0 border-end pb-3"><span v-html="forms.collection_forecast[2].general_remark"></span></td>
                    <td colspan="4" class="border-top-0 border-end pb-3"><span v-html="forms.collection_forecast[3].general_remark"></span></td>
                    <td colspan="4" class="border-top-0 border-end pb-3"><span v-html="forms.collection_forecast[4].general_remark"></span></td>
                </tr>
                <tr>
                  <td colspan="20" class="text-center border-top">Average <b>{{forms.average_collection_forecast.label}}-Days</b> Collection Forecast</td>
                </tr>
              </tbody>
              </table>
            </div>
            <div class="col-12">
              <div class="sectionPart">Part D: Projected Income</div>
            </div>
            <div class="col-12">
              <table class="border border-dark align-middle customTable2 text-center">
                <thead class="fw-medium">
                  <tr>
                    <th colspan="2"><span v-if="forms.pre_factoring_purpose_type=='Term Advance'">{{forms.pre_factoring_purpose_type}}</span> Pre-Factoring</th>
                    <th colspan="3">Factoring</th>
                    <th rowspan="2">Total</th>
                  </tr>
                  <tr>
                    <th>Processing Fee</th>
                    <th width="120">Profit</th>
                    <th>Processing Fee</th>
                    <th>Administration Fee</th>
                    <th width="80">Profit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td height="30px">
                      <span class="d-block">{{forms.currency}}</span>
                      <div v-if="forms.project_income.term_advance_pre_factoring.processing_fee">
                        {{convertCurrencyFormat(forms.project_income.term_advance_pre_factoring.processing_fee || 0, false) }}
                      </div>
                      <div v-else>0</div>
                    </td>
                    <td>
                      <span class="d-block">{{forms.currency}}</span>
                      <div v-if="forms.project_income.term_advance_pre_factoring.profit">
                        {{convertCurrencyFormat(forms.project_income.term_advance_pre_factoring.profit || 0, false) }}
                      </div>
                      <div v-else>0</div>
                    </td>
                    <td>
                      <span class="d-block">{{forms.currency}}</span>
                      <div v-if="forms.project_income.factoring.processing_fee">
                        {{convertCurrencyFormat(forms.project_income.factoring.processing_fee || 0, false) }}
                      </div>
                      <div v-else>0</div>
                    </td>
                    <td>
                      <span class="d-block">{{forms.currency}}</span>
                      <div v-if="forms.project_income.factoring.administration_fee">
                        {{convertCurrencyFormat(forms.project_income.factoring.administration_fee || 0, false) }}
                      </div>
                      <div v-else>0</div>
                    </td>
                    <td>
                      <span class="d-block">{{forms.currency}}</span>
                      <div v-if="forms.project_income.factoring.profit">
                        {{convertCurrencyFormat(forms.project_income.factoring.profit|| 0, false) }}
                      </div>
                      <div v-else>0</div>
                    </td>
                    <td>
                      <span class="d-block">{{forms.currency}}</span>
                      <div v-if="forms.project_income.total">
                        {{convertCurrencyFormat(forms.project_income.total, false) }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12">
              <div class="sectionPart2">Approver</div>
            </div>
            <div class="col-12 pb-2">
              <div class="d-flex text-dark">
                <div class="col-6 text-center me-1" >
                  <div class="mb-2">
                  <span v-if="forms.approver1_approval_status==''">Approved / Declined</span> 
                  <span v-if="forms.approver1_approval_status=='declined'"><del>Approved</del> / Declined</span> 
                  <span v-if="forms.approver1_approval_status=='approved'">Approved / <del>Declined</del></span> 
                  </div>
                  <table class="w-100 text-start mb-0" v-if="forms.approver_1">
                    <tr>
                      <td></td>
                      <td height="60" class="text-center border-bottom border-dark pb-1">
                        <div>
                        <img v-if="forms.approver_1.signature && forms.approver1_approval_status=='approved'" :src="forms.approver_1.signature" height="60" class="convert2dataurl">
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="50">Name:<br>Position:<br>Date:</td>
                      <td class="align-top">{{forms.approver_1.name}}<br>{{forms.approver_1.position}}<br>{{forms.approver1_approval_date}}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-6 text-center ms-1">
                  <div class="mb-2">
                  <span v-if="forms.approver2_approval_status==''">Approved / Declined</span> 
                  <span v-if="forms.approver2_approval_status=='declined'"><del>Approved</del> / Declined</span> 
                  <span v-if="forms.approver2_approval_status=='approved'">Approved / <del>Declined</del></span> 
                  </div>
                  <table class="w-100 text-start mb-0" v-if="forms.approver_1">
                    <tr>
                      <td></td>
                      <td height="60" class="text-center border-bottom border-dark pb-1">
                        <div>
                        <img v-if="forms.approver_2.signature && forms.approver2_approval_status=='approved'" :src="forms.approver_2.signature" height="60" class="convert2dataurl">
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="50">Name:<br>Position:<br>Date:</td>
                      <td class="align-top">{{forms.approver_2.name}}<br>{{forms.approver_2.position}}<br>{{forms.approver2_approval_date}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="row justify-content-between align-items-center font-size-10 mt-1">
              <div class="col">
                <div class="mb-1 tex-dark">ROCE <strong class="ms-1">{{ forms.roce }}%</strong></div>
              </div>
              <div class="col">
                <div class="mb-1 tex-dark">Tenure: <strong class="ms-1">{{ forms.project_tenure }}</strong> Months</div>
              </div>
              <div class="col">
                <div class="mb-1 tex-dark">Annualised ROCE: <strong class="ms-1">{{ forms.annualised_roce }}%</strong></div>
              </div>
              <div class="col text-end">
                <div class="mb-1 tex-dark">Collection Mode: <strong class="ms-1">{{ forms.payment_mode.value }}</strong></div>
              </div>
            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end"><span class="text-dark">1</span> | Page</div>
            </div>
          </div>
          </div>
          <div class="printSection">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12"><div class="bg-dark text-center text-white text-uppercase font-size-14 fw-bold">Factoring Application</div></div>
              <div class="col-auto ms-auto">
                <div class="bg-light text-center text-dark text-uppercase px-2 py-1 fw-semibold font-size-10">Document B</div>
              </div>
            </div>
            <div class="row" v-if="forms.facility_information">
              <div class="col-12">
                <div class="sectionPart mb-0">Part A: Facility Information</div>
              </div>
              <div class="col-12 mb-3">
                <table class="border border-dark border-top-0 mb-1 mb-0 align-middle customTable">
                        <thead class="fw-medium font-size-14 align-middle text-center">
                          <tr>
                              <th colspan="2" class="border-end">Pre-Factoring</th>
                              <th colspan="2">Factoring</th>
                          </tr>
                          <tr>
                              <th class="border-top border-bottom border-end" width="25">Type</th>
                              <th class="border-top border-bottom border-end" width="25">Amount <span class="d-block">{{forms.currency}}</span></th>
                              <th class="border-top border-bottom border-end" width="25">Type</th>
                              <th class="border-top border-bottom" width="25">Amount <span class="d-block">{{forms.currency}}</span></th>
                          </tr>
                        </thead>
                        <tbody class="text-end">
                          <tr>
                              <td class="border-light2 border-end text-start p-1">Supplier Advance</td>
                              <td class="border-light2 border-end p-1">
                                <div v-if="forms.facility_information.supplier_advance">
                                  {{convertCurrencyFormat(forms.facility_information.supplier_advance, false) }}
                                </div>
                              </td>
                              <td class="border-light2 border-end text-start p-1">Invoice Advance</td>
                              <td class="border-light2 p-1">
                                <div v-if="forms.facility_information.invoice_advance">
                                  {{convertCurrencyFormat(forms.facility_information.invoice_advance, false) }}
                                </div>
                              </td>
                          </tr>
                          <tr>
                              <td class="border-light2 border-end text-start p-1">Cash Advance</td>
                              <td class="border-light2 border-end p-1"> 
                                <div v-if="forms.facility_information.cash_advance">
                                  {{convertCurrencyFormat(forms.facility_information.cash_advance, false) }}
                                </div>
                              </td>
                              <td class="border-light2 border-end text-start p-1">Collection Agent</td>
                              <td class="border-light2 p-1"> 
                                <div v-if="forms.facility_information.collection_agent">
                                  {{convertCurrencyFormat(forms.facility_information.collection_agent, false) }}
                                </div>
                              </td>
                          </tr>
                          <tr>
                              <td class="border-light2 border-end text-start p-1">Term Advance</td>
                              <td class="border-light2 border-end p-1"> 
                                <div v-if="forms.facility_information.term_advance">
                                  {{convertCurrencyFormat(forms.facility_information.term_advance, false) }}
                                </div>
                              </td>
                              <td class="border-light2 border-end text-start p-1">Letter of Support</td>
                              <td class="border-light2 p-1">
                                <div v-if="forms.facility_information.letter_of_support">
                                  {{convertCurrencyFormat(forms.facility_information.letter_of_support, false) }}
                                </div>
                              </td>
                          </tr>
                          <tr>
                              <td class="border-light2 border-end text-start p-1">Bond Advance</td>
                              <td class="border-light2 border-end p-1"> 
                                <div v-if="forms.facility_information.bond_advance">
                                  {{convertCurrencyFormat(forms.facility_information.bond_advance, false) }}
                                </div>
                              </td>
                              <td class="border-light2 border-end text-start p-1">Letter of Undertaking</td>
                              <td class="border-light2 p-1"> 
                                <div v-if="forms.facility_information.letter_of_undertaking">
                                  {{convertCurrencyFormat(forms.facility_information.letter_of_undertaking, false) }}
                                </div>
                              </td>
                          </tr>
                        </tbody>
                    </table>
              </div>
              <div class="col-12">
                <div class="sectionPart border-bottom-0 mb-0">Part B: Applicant Information</div>
              </div>
              <div class="col-12 mb-3 text-dark">
                  <form class="border border-dark p-2">
                    <div class="mb-2 position-relative d-flex">
                        <label>Name of Entity:</label>
                        <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.company_name}}</div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                          <div class="mb-2 position-relative d-flex">
                              <label>Date of Registration:</label>
                              <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.date_of_registration}}</div>
                          </div>
                        </div>
                        <div class="col-auto ms-auto">
                          <div class="mb-2 position-relative d-flex">
                              <label>Business Registration No.</label>
                              <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.business_registration_no}}</div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="mb-2 position-relative d-flex text-nowrap">
                              <label class="text-nowrap me-2">Type of Entity:</label>
                              <div v-if="forms.applicant" class="ms-2 w-100">
                                <div class="row">
                                  <div class="col-4 d-flex justify-content-end align-items-center">
                                    <span class="me-2">Private Limited </span>
                                    <div class="square_box"><span v-if="forms.applicant.type_of_entity.value=='private_limited'">√</span></div>
                                  </div>
                                  <div class="col-4 d-flex justify-content-end align-items-center">
                                    <span class="me-2">Partnership</span>
                                    <div class="square_box"><span v-if="forms.applicant.type_of_entity.value=='partnership'">√</span></div>
                                  </div>
                                  <div class="col-4 d-flex justify-content-end align-items-center">
                                    <span class="me-2">Proprietorship</span>
                                    <div class="square_box"><span v-if="forms.applicant.type_of_entity.value=='proprietorship'">√</span></div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                          <div class="mb-2 position-relative d-flex">
                              <label>Business Address:</label>
                              <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.business_address}}</div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-2 position-relative d-flex">
                              <label>Telephone Number:</label>
                              <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.business_tel}}</div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-2 position-relative d-flex">
                              <label>E-Mail Address:</label>
                              <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.business_email}}</div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-2 position-relative d-flex">
                              <label>Contact Person:</label>
                              <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.contact_person}}</div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-2 position-relative d-flex">
                              <label>Position:</label>
                              <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.position}}</div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-0 position-relative d-flex">
                              <label>Telephone Number:</label>
                              <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.contact_tel}}</div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-0 position-relative d-flex">
                              <label>E-Mail Address:</label>
                              <div v-if="forms.applicant" class="ms-2"> {{forms.applicant.contact_email}}</div>
                          </div>
                        </div>
                    </div>
                  </form>
              </div>
              <div class="col-12 mb-2">
                <div class="sectionPart mb-0">Part C: Bank Account Information</div>
              </div>
              <div class="col-12 mb-3">
                  <table class="border border-dark mb-1 mb-0 align-middle customTable">
                      <thead class="fw-medium align-middle text-center">
                        <tr class="border-bottom border-dark">
                            <th>Bank</th>
                            <th>Account Number</th>
                            <th>Facility</th>
                            <th>Limit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="border-light2 border-end"></td>
                          <td class="border-light2 border-end"></td>
                          <td class="border-light2 border-end"></td>
                          <td class="border-light2 text-center">{{forms.currency}}</td>
                        </tr>
                        <tr v-for="(value, index) in formData.banks" :key="index">
                            <td class="border-light2 border-end text-start"> <span v-if="value.bank_name ">{{value.bank_name || '-'}}, </span> {{value.branch || '-'}} </td>
                            <td class="border-light2 border-end text-center"> {{value.account_number|| '-'}}</td>
                            <td class="border-light2 border-end text-center"> {{value.facility|| '-'}}</td>
                            <td class="text-center">
                              <span v-if="value.limit">
                              {{ convertCurrencyFormat(value.limit, false ) }}
                              </span>
                            </td>
                        </tr>
                        <tr>
                          <td class="border-light2 border-end" style="height:10px"></td>
                          <td class="border-light2 border-end"></td>
                          <td class="border-light2 border-end"></td>
                          <td class="border-light2"></td>
                        </tr>
                      </tbody>
                  </table>
              </div>
              <div class="col-12 mb-2">
                <div class="sectionPart mb-0">Part D:  Contract Information</div>
              </div>
              <div class="col-12 mb-3">
                  <table class="border border-dark mb-1 mb-0 align-middle customTable">
                      <thead class="fw-medium font-size-14 align-middle text-center">
                        <tr class="border-bottom border-dark">
                            <th class="border-end border-light2">Contract Title</th>
                            <th class="border-end border-light2">Awarder</th>
                            <th class="border-end border-light2 text-nowrap">Nature of Contract</th>
                            <th class="border-end border-light2">Amount </th>
                            <th>ePerolehan </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td class="text-start border-end border-light2 " width="45%">
                              <div v-html="forms.contract_title" class="text-wrap"></div>
                            </td>
                            <td class="text-start border-end border-light2">
                              <div v-html="forms.contract_awarder" class="text-wrap"></div>
                            </td>
                            <td class="text-center border-end border-light2">
                              {{forms.nature_of_contract.label}}
                            </td>
                            <td class="text-center border-end border-light2">
                              {{forms.currency}} 
                              <div v-if="forms.contract_amount">
                                  {{convertCurrencyFormat(forms.contract_amount, false) }}
                              </div>
                            </td>
                            <td class="text-start">
                              <div class="d-flex w-100 text-center">
                                  <div class="w-100">Yes</div>
                                  <div class="w-100">No</div>
                              </div>
                              <div class="d-flex text-center">
                                  <div class="d-flex justify-content-center align-items-center w-100" v-if="forms.payment_mode">
                                    <div class="square_box"><span v-if="forms.payment_mode.value==payment_mode[0].value">√</span></div>
                                  </div>
                                  <div class="d-flex justify-content-center align-items-center w-100" v-if="forms.payment_mode">
                                    <div class="square_box"><span v-if="forms.payment_mode.value==payment_mode[1].value">√</span></div>
                                  </div>
                                  
                              </div>
                            </td>
                        </tr>
                        <tr class="pt-2">
                            <td class="border-top-0 border-end border-light2">&nbsp;</td>
                            <td class="border-top-0 border-end border-light2"></td>
                            <td class="border-top-0 border-end border-light2"></td>
                            <td class="border-top-0 border-end border-light2"></td>
                            <td class="border-top-0">
                              <div class="d-flex text-center">
                                  <div class="d-flex justify-content-center align-items-center w-100">
                                    <div class="square_box"> </div>
                                  </div>
                                  <div class="d-flex justify-content-center align-items-center w-100">
                                    <div class="square_box"> </div>
                                  </div>
                                  
                              </div>
                            </td>
                        </tr>
                      </tbody>
                  </table>
              </div>
            
            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end"><span class="text-dark">2</span> | Page</div>
            </div>

          </div>
          
          <div class="printSection">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                  <div class="sectionPart mb-0">Part E:  Pre-Factoring Payee</div>
              </div>
              <div class="col-12 mb-2">
                <table class="border border-dark mb-1 mb-0 align-middle customTable">
                  <thead class="fw-medium font-size-14 align-middle text-center">
                      <tr class="border-bottom border-dark">
                        <th class="border-end border-light2 text-nowrap">Payee Company</th>
                        <th class="border-end border-light2">Location</th>
                        <th class="border-end border-light2">Purpose</th>
                        <th class="border-end border-light2">Amount</th>
                        <th class="border-end border-light2">Currency</th>
                        <th class="border-end border-light2">Rate</th>
                        <th>Total ({{forms.currency}})</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(value, index) in forms.pre_factoring_payee" :key="index">
                        <td class="text-start border-end border-light2 text-nowrap py-2" >
                            {{value.payee_company}}
                        </td>
                        <td class="text-start border-end border-light2 py-2">
                          {{value.location}}
                        </td>
                        <td class="text-justify border-end border-light2 py-2">
                          {{value.purpose}}
                        </td>
                        <td class="text-end border-end border-light2 py-2" >
                          <span v-if="value.amount">
                            {{convertCurrencyFormat(value.amount, false) }}
                          </span>
                        </td>
                        <td class="text-center border-end border-light2 py-2">
                          <span>{{value.currency}}</span>
                        </td>
                        <td class="text-center border-end border-light2 py-2" >
                        <span>{{value.rate}}</span>
                        </td>
                        <td class="text-end py-1">
                          <span v-if="value.total && value.amount">
                            {{convertCurrencyFormat(value.total, false) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="text-end" :class="{'border-top':forms.pre_factoring_payee.length>1}">
                            <span v-if="forms.pre_factoring_payee.length>1">
                              {{ convertCurrencyFormat(forms.pre_factoring_payee_total_amount|| '0') }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="border-top-0 border-end border-light2"></td>
                        <td class="text-start border-top-0">
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-12 mb-2" v-if="!(forms.pre_factoring_payee.length>12)">
                  <div class="sectionPart mb-0">Part F: Required Documents Checklist</div>
              </div>
              <div class="col-12" v-if="!(forms.pre_factoring_payee.length>12)">
                  <!-- <pre class="bg-light p-3 rounded" style="height:300px">{{formData.supporting_docs}}</pre> -->
                  <div class="mb-2">
                    <div class="border border-dark py-1 px-2 customTable text-dark font-size-11">
                        <div class="d-flex">
                          <div class="col-8 col-md-8 mb-2"></div>
                          <div class="col-2 col-md-2 mb-2">
                              <div class="d-flex text-center">
                                <div class="w-100">
                                    Yes
                                </div>
                                <div class="w-100">  
                                    No 
                                </div>
                              </div>
                          </div>
                          <div class="col-2 col-md-2 mb-2">Remarks </div>
                        </div>
                        <div class="d-flex" v-for="(value,index) in formData.supporting_docs.slice(0, -2)" :key="index">
                            <div class="col-8 col-md-8" :class="{'mb-2':index !== (formData.supporting_docs.length-1) }" v-show="!value.checkYesNo==''">{{index+1}}. {{ value.name }} </div>
                            <div class="col-2 col-md-2" :class="{'mb-2':index !== (formData.supporting_docs.length-1) }" v-show="!value.checkYesNo==''">
                                <div class="d-flex text-center">
                                  <div class="d-flex justify-content-center align-items-center w-100">
                                    <div class="square_box"><span v-if="value.checkYesNo=='checked'">√</span></div>
                                  </div>
                                  <div class="d-flex justify-content-center align-items-center w-100">
                                    <div class="square_box"><span v-if="value.checkYesNo=='unchecked'">√</span></div>
                                  </div>
                                </div>
                          </div>
                          <div class="col-2 col-md-2" v-show="!value.checkYesNo==''" :class="{'mb-2':index !== (formData.supporting_docs.length-1) }">
                              <div v-if="value.id !== 8 && value.id !==9">
                                <span v-if="value.checkYesNo==''">Pending</span>
                                <span v-if="value.checkYesNo=='checked'">{{value.remark}}</span>
                                <span v-if="value.checkYesNo=='unchecked'">{{value.remark}}</span>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="col-12 mb-1" v-if="!(forms.pre_factoring_payee.length>3)">
                <div class="sectionPart mb-0">Part G: Declaration</div>
              </div>
              <div class="col-12" v-if="!(forms.pre_factoring_payee.length>3)">
                  <div class="px-2">
                    <ol class="list-group ps-3 text-dark">
                        <li class="mb-2">All information furnished above and as contained in the attached documents requested are true and accurate. </li>
                        <li class="mb-2">I/We hereby authorise Ikhlas Al Dain Sdn Bhd to verify any information pertaining to our company, its directors, and its shareholders, from any source deemed appropriate for the purpose of assessing this application. </li>
                        <li class="mb-2">I/We understand that Ikhlas Al Dain Sdn Bhd reserves the right at its sole absolute discretion to decline this application and without providing the reason/s for it.</li>
                    </ol>
                    <div class="text-nowrap text-center mb-3 text-dark">
                        <div class="d-flex">
                          <div>
                              <div class="border-bottom border-dark" style="height:90px; min-width: 150px;"></div>
                              <div class="px-1 text-start">Name: {{ forms.name_of_authorised_signatory }}</div>
                          </div>
                          <div class="mx-3">
                              <div class="d-flex w-100" style="height:90px">
                                <span class="mt-auto">Authorised Signatory</span>
                              </div>
                              <div class="py-0">
                                <div class="d-flex w-100 lh-1" style="margin-top:3px">
                                    Date: {{forms.date_of_authorised_signatory}}
                                </div>
                              </div>
                          </div>
                          <div class="ms-auto">
                              <div class="border border-dark" style="height:90px;width: 180px;">
                              </div>
                              <div class="text-center mt-1">Affix Company Stamp</div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end"><span class="text-dark">3</span> | Page</div>
            </div>
          </div>

          <!-- Only Part F & G -->
          <div class="printSection" v-if="forms.pre_factoring_payee.length>12">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                  <div class="sectionPart mb-0">Part F: Required Documents Checklist</div>
              </div>
              <div class="col-12">
                  <!-- <pre class="bg-light p-3 rounded" style="height:300px">{{formData.supporting_docs}}</pre> -->
                  <div class="mb-2">
                    <div class="border border-dark py-1 px-2 customTable text-dark font-size-12">
                        <div class="d-flex">
                          <div class="col-8 col-md-8 mb-2"></div>
                          <div class="col-2 col-md-2 mb-2">
                              <div class="d-flex text-center">
                                <div class="w-100">
                                    Yes
                                </div>
                                <div class="w-100">  
                                    No 
                                </div>
                              </div>
                          </div>
                          <div class="col-2 col-md-2 mb-2">Remarks </div>
                        </div>
                        <div class="d-flex" v-for="(value,index) in formData.supporting_docs.slice(0, -2)" :key="index">
                          <div class="col-8 col-md-8" :class="{'mb-2':index !== (formData.supporting_docs.length-1) }">{{index+1}}. {{ value.name }} </div>
                          <div class="col-2 col-md-2" :class="{'mb-2':index !== (formData.supporting_docs.length-1) }">
                              <div class="d-flex text-center">
                                <div class="d-flex justify-content-center align-items-center w-100">
                                  <div class="square_box"><span v-if="value.checkYesNo=='checked'">√</span></div>
                                </div>
                                <div class="d-flex justify-content-center align-items-center w-100">
                                  <div class="square_box"><span v-if="value.checkYesNo=='unchecked'">√</span></div>
                                </div>
                              </div>
                          </div>
                          <div class="col-2 col-md-2" :class="{'mb-2':index !== (formData.supporting_docs.length-1) }">
                              <div v-if="value.id !== 8 && value.id !==9">
                                <span v-if="value.checkYesNo==''">Pending</span>
                                <span v-if="value.checkYesNo=='checked'">{{value.remark}}</span>
                                <span v-if="value.checkYesNo=='unchecked'">{{value.remark}}</span>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="col-12 mb-1">
                <div class="sectionPart mb-0">Part G: Declaration</div>
              </div>
              <div class="col-12">
                  <div class="px-2">
                    <ol class="list-group ps-3 text-dark">
                        <li class="mb-2">All information furnished above and as contained in the attached documents requested are true and accurate. </li>
                        <li class="mb-2">I/We hereby authorise Ikhlas Al Dain Sdn Bhd to verify any information pertaining to our company, its directors, and its shareholders, from any source deemed appropriate for the purpose of assessing this application. </li>
                        <li class="mb-2">I/We understand that Ikhlas Al Dain Sdn Bhd reserves the right at its sole absolute discretion to decline this application and without providing the reason/s for it.</li>
                    </ol>
                    <div class="text-nowrap text-center mb-3 text-dark">
                        <div class="d-flex">
                          <div>
                              <div class="border-bottom border-dark" style="height:90px; min-width: 150px;"></div>
                              <div class="px-1 text-start">Name: {{ forms.name_of_authorised_signatory }}</div>
                          </div>
                          <div class="mx-3">
                              <div class="d-flex w-100" style="height:90px">
                                <span class="mt-auto">Authorised Signatory</span>
                              </div>
                              <div class="py-0">
                                <div class="d-flex w-100 lh-1" style="margin-top:3px">
                                    Date: {{forms.date_of_authorised_signatory}}
                                </div>
                              </div>
                          </div>
                          <div class="ms-auto">
                              <div class="border border-dark" style="height:90px;width: 180px;">
                              </div>
                              <div class="text-center mt-1">Affix Company Stamp</div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end"><span class="text-dark">4</span> | Page</div>
            </div>
          </div>
          <!-- Only Part G -->
          <div class="printSection" v-if="forms.pre_factoring_payee.length>3 && forms.pre_factoring_payee.length<=12">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-1">
                <div class="sectionPart mb-0">Part G: Declaration</div>
              </div>
              <div class="col-12">
                  <div class="px-2">
                    <ol class="list-group ps-3 text-dark">
                        <li class="mb-2">All information furnished above and as contained in the attached documents requested are true and accurate. </li>
                        <li class="mb-2">I/We hereby authorise Ikhlas Al Dain Sdn Bhd to verify any information pertaining to our company, its directors, and its shareholders, from any source deemed appropriate for the purpose of assessing this application. </li>
                        <li class="mb-2">I/We understand that Ikhlas Al Dain Sdn Bhd reserves the right at its sole absolute discretion to decline this application and without providing the reason/s for it.</li>
                    </ol>
                    <div class="text-nowrap text-center mb-3 text-dark">
                        <div class="d-flex">
                          <div>
                              <div class="border-bottom border-dark" style="height:90px; min-width: 150px;"></div>
                              <div class="px-1 text-start">Name: {{ forms.name_of_authorised_signatory }}</div>
                          </div>
                          <div class="mx-3">
                              <div class="d-flex w-100" style="height:90px">
                                <span class="mt-auto">Authorised Signatory</span>
                              </div>
                              <div class="py-0">
                                <div class="d-flex w-100 lh-1" style="margin-top:3px">
                                    Date: {{forms.date_of_authorised_signatory}}
                                </div>
                              </div>
                          </div>
                          <div class="ms-auto">
                              <div class="border border-dark" style="height:90px;width: 180px;">
                              </div>
                              <div class="text-center mt-1">Affix Company Stamp</div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end">
                <span class="text-dark">4</span>  | Page
              </div>
            </div>
          </div>
          <div class="printSection">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="bg-dark text-center text-white text-uppercase font-size-14 fw-bold">Facility Term Sheet</div>
              </div>
              <div class="col-auto ms-auto">
                <div class="bg-light text-center text-dark text-uppercase px-2 py-1 fw-semibold font-size-10">Document C</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <table class="customTable termsheet">
                  <thead>
                    <tr>
                      <th width="160px">Facility:</th>
                      <th>Factoring</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Applicant:</td>
                      <td><div v-if="forms.applicant">{{ forms.applicant.company_name || '' }}</div>
                      <div v-else>-</div></td>
                    </tr>
                    <tr>
                      <td class="align-middle">Purpose:</td>
                      <td>
                        <div v-if="forms.facility_term_sheet">
                          {{forms.facility_term_sheet.factoring.purpose}}
                        </div>
                    </td>
                    </tr>
                    <tr>
                      <td>Limit:</td>
                      <td><div v-if="forms.facility_term_sheet">
                        {{forms.currency}} {{ convertCurrencyFormat(forms.facility_term_sheet.factoring.limit || '0') }}</div></td>
                    </tr>
                    <tr>
                      <td>Administration Fee:</td>
                      <td>{{forms.facility_term_sheet.factoring.administration_fee || '-'}}% <span v-if="forms.nature_of_contract.value=='milestone_staggered'">flat</span> of Gross Invoice Value, charged on a per invoice basis.
                      </td>
                    </tr>
                    <tr>
                      <td>Profit Rate:</td>
                      <td>{{forms.facility_term_sheet.factoring.profit_rate || '-'}}% per month of Advanced amount, calculated on a per day basis.
                      </td>
                    </tr>
                    <tr>
                      <td>Rollover Fee:</td>
                      <td>{{forms.facility_term_sheet.factoring.rollover_fee || '-'}}% per month of Gross Invoice Value, calculated on a per day basis and charged upon expiry of Credit Period.

                      </td>
                    </tr>
                    <tr>
                      <td>Margin of Advance:</td>
                      <td>Up to {{forms.facility_term_sheet.factoring.margin_of_advance || '-'}}% of Gross Invoice Value.
                      </td>
                    </tr>
                    <tr>
                      <td>Credit Period:</td>
                      <td>{{ forms.facility_term_sheet.factoring.credit_period }} days from date of invoice.
                      </td>
                    </tr>
                    <tr>
                      <td>Recourse Period:</td>
                      <td>{{ forms.facility_term_sheet.factoring.recourse_period }} days upon expiry of Credit Period.
                      </td>
                    </tr>
                    <tr>
                      <td>Security and Credit Support:</td>
                      <td>
                        <ol class="list-group-numbered p-0 m-0">
                          <li class="list-group-item mb-2" v-for="(value, index) in forms.security_and_credit_support.factoring" :key="index" v-show="forms.security_and_credit_support.factoring[index]">{{forms.security_and_credit_support.factoring[index]}}</li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>Processing Fees:</td>
                      <td>
                        <div v-if="forms.facility_term_sheet" class="mb-1" >
                        {{forms.currency}} {{ convertCurrencyFormat(forms.facility_term_sheet.factoring.processing_fees || '0') }}
                        </div>
                        The fees do not include stamp duty to be charged under the Stamp Act, 1949.
                      </td>
                    </tr>
                    <tr class="d-none">
                      <td>Letter of Undertaking Fees:</td>
                      <td>
                        <table class="w-100">
                          <tr>
                            <td class="border border-dark bg-light p-1 fw-medium"  width="50%" >Amount of Undertaking<span class="d-block">{{forms.currency}}</span>
                            </td>
                            <td class="border border-dark border-start-0 p-1 bg-light fw-medium" width="50%" >Fee<span class="d-block">%</span></td>
                          </tr>
                          <tr>
                            <td class="px-2 pt-2">
                              Up to 250,000 
                            </td>
                            <td class="px-2 pt-2">0.5% of Amount of Undertaking</td>
                          </tr>
                          <tr>
                            <td class="px-2">
                              Above 250,000 to 500,000
                            </td>
                            <td class="px-2">0.75% of Amount Undertaking</td>
                          </tr>
                          <tr>
                            <td class="px-2">
                              Above 500,000 to below 1.0 million
                            </td>
                            <td class="px-2">1% of Amount of Undertaking</td>
                          </tr>
                          <tr>
                            <td class="px-2">
                              1.0 million and above
                            </td>
                            <td class="px-2">1.25% of Amount of Undertaking</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr class="d-none">
                      <td>Support Letter Fees:</td>
                      <td>
                        <table class="w-100">
                          <tr>
                            <td class="border border-dark bg-light fw-medium" width="50%" >Amount Supported<span class="d-block">{{forms.currency}}</span>
                            </td>
                            <td width="50%" class="border border-dark border-start-0 bg-light fw-medium">Fee<span class="d-block">%</span></td>
                          </tr>
                          <tr>
                            <td class="px-2 pt-2">
                              Up to 500,000
                            </td>
                            <td class="px-2 pt-2">50 per Letter</td>
                          </tr>
                          <tr>
                            <td class="px-2">
                              Above 500,000 to below 1.0 million
                            </td>
                            <td class="px-2">75 per Letter</td>
                          </tr>
                          <tr>
                            <td class="px-2">
                              1.0 million
                            </td>
                            <td class="px-2">100 per Letter</td>
                          </tr>
                          <tr>
                            <td class="px-2">
                              Above 1.0 million to below 2.0 million
                            </td>
                            <td class="px-2">200 per Letter</td>
                          </tr>
                          <tr>
                            <td class="px-2">
                              2.0 million and above
                            </td>
                            <td class="px-2">250 per Letter</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end">
                <span class="text-dark" v-if="forms.pre_factoring_payee.length>3">
                  5
                </span>
                <span class="text-dark" v-else>4</span>  | Page
              </div>
            </div>
          </div>
          <div class="printSection" v-show="forms.facility_term_sheet.pre_factoring.enabled=='true'">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="bg-dark text-center text-white text-uppercase font-size-14 fw-bold">Facility Term Sheet</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-3">

                <table class="customTable termsheet" v-if="this.forms.nature_of_contract.value=='recurring'">
                  <thead>
                    <tr>
                      <th width="160px">Facility:</th>
                      <th>Pre-Factoring</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Applicant:</td>
                      <td><div v-if="forms.applicant">{{ forms.applicant.company_name }}</div>
                      <div v-else>-</div></td>
                    </tr>
                    <tr>
                      <td class="align-middle">Purpose:</td>
                      <td>
                        <div v-if="forms.facility_term_sheet">
                          {{forms.facility_term_sheet.pre_factoring.purpose}}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Limit:</td>
                      <td><div v-if="forms.facility_term_sheet">
                        {{forms.currency}} {{ convertCurrencyFormat(forms.facility_term_sheet.pre_factoring.limit || '0') }}</div></td>
                    </tr>
                    <tr>
                      <td>Profit Rate:</td>
                      <td>{{forms.facility_term_sheet.pre_factoring.profit_rate || '-'}}%  flat per month of Advanced amount
                      </td>
                    </tr>
                    <tr>
                      <td>Rollover Fee:</td>
                      <td>{{forms.facility_term_sheet.pre_factoring.rollover_fee || '-'}}% per month of Advanced amount, charged upon expiry of each monthly Instalment Due Date and calculated on a per day basis.
                      </td>
                    </tr>
                    <tr>
                      <td>Margin of Advance:</td>
                      <td>Up to {{forms.facility_term_sheet.pre_factoring.margin_of_advance || '-'}}% of working capital requirement

                      </td>
                    </tr>
                    <tr>
                      <td>Tenure:</td>
                      <td>{{forms.facility_term_sheet.pre_factoring.tenure || '-'}} months
                      </td>
                    </tr>
                    <tr>
                      <td>Repayments:</td>
                      <td>Through {{forms.facility_term_sheet.pre_factoring.tenure || '-'}} fixed and equal monthly instalments of {{forms.currency}} {{convertCurrencyFormat(forms.facility_term_sheet.pre_factoring.repayments || '0') }}  each either by setoffs against factored contract proceeds or repayments by the Applicant.  

                      </td>
                    </tr>
                    <tr>
                      <td>Instalment Due Dates:</td>
                      <td>{{forms.facility_term_sheet.pre_factoring.installment_due_dates.label || '-'}} ({{forms.facility_term_sheet.pre_factoring.installment_due_dates.value || '-'}}) month from date of Advance.


                      </td>
                    </tr>
                    <tr>
                      <td>Security and Credit Support:</td>
                      <td>
                        <ol class="list-group-numbered p-0 mb-0">
                          <li class="list-group-item mb-2" v-for="(value, index) in forms.security_and_credit_support.pre_factoring" :key="index" v-show="forms.security_and_credit_support.pre_factoring[index]">{{forms.security_and_credit_support.pre_factoring[index]}}</li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>Processing Fees:</td>
                      <td>
                        <div v-if="forms.facility_term_sheet" class="mb-2">
                        {{forms.currency}} {{ convertCurrencyFormat(forms.facility_term_sheet.pre_factoring.processing_fees || '0') }}
                        </div>
                        The fees do not include stamp duty to be charged under the Stamp Act, 1949.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="customTable termsheet" v-else>
                  <thead>
                    <tr>
                      <th width="160px">Facility:</th>
                      <th>Pre-Factoring</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Applicant:</td>
                      <td><div v-if="forms.applicant">{{ forms.applicant.company_name }}</div>
                      <div v-else>-</div></td>
                    </tr>
                    <tr>
                      <td class="align-middle">Purpose:</td>
                      <td>
                        <div v-if="forms.facility_term_sheet">
                          {{forms.facility_term_sheet.pre_factoring.purpose}}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Limit:</td>
                      <td><div v-if="forms.facility_term_sheet">
                        {{forms.currency}} {{ convertCurrencyFormat(forms.facility_term_sheet.pre_factoring.limit || '0') }}</div></td>
                    </tr>
                    <tr>
                      <td>Profit Rate:</td>
                      <td>

                        <div class="row" v-if="forms.nature_of_contract.value == 'one_off'">
                          <div class="col-6 fw-bold mb-2">Purpose</div>
                          <div class="col-6 fw-bold mb-2">Rate</div>
                          <div class="col-6 mb-2">
                            Supplier Advance/Bond Advance
                          </div>
                          <div class="col-6 mb-2">
                            {{
                              forms.facility_term_sheet.pre_factoring
                                .supplier_bond_advance_rate
                            }}% per month of Advanced amount,<br>calculated
                            on a daily basis.
                          </div>
                          <div class="col-6 mb-2">Cash Advance</div>
                          <div class="col-6 mb-2">
                            {{
                              forms.facility_term_sheet.pre_factoring
                                .cash_advance_rate
                            }}% per month of Advanced amount,<br>calculated
                            on a daily basis.
                          </div>
                        </div>
                        <div class="row" v-else>
                          <div class="col-6 fw-bold mb-2">Supplier Advance / Bond Advance</div>
                          <div class="col-6 fw-bold mb-2">Cash Advance</div>
                          <div class="col-6 mb-2">
                            {{ forms.facility_term_sheet.pre_factoring.supplier_bond_advance_rate }}% per month of Advanced amount,<br>calculated on a daily basis.
                          </div>
                          <div class="col-6 mb-2">
                            {{ forms.facility_term_sheet.pre_factoring.cash_advance_rate}}% per month of Advanced amount,<br>calculated on a daily basis.
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Rollover Fee:</td>
                      <td>{{forms.facility_term_sheet.pre_factoring.rollover_fee || '-'}}% per month of the balance outstanding, calculated on a per day basis and charged upon expiry of Credit Period.
                      </td>
                    </tr>
                    <tr>
                      <td>Margin of Advance:</td>
                      <td>Up to {{forms.facility_term_sheet.pre_factoring.margin_of_advance || '-'}}% of purchase price or incurred cost.

                      </td>
                    </tr>
                    <tr>
                        <td>Credit Period:</td>
                        <td>{{ forms.facility_term_sheet.pre_factoring.credit_period }} days from date of Advance.
                        </td>
                      </tr>
                      <tr>
                        <td>Recourse Period:</td>
                        <td>{{ forms.facility_term_sheet.pre_factoring.recourse_period }} days upon expiry of Credit Period.
                        </td>
                      </tr>
                    <!-- <tr>
                      <td>Tenure:</td>
                      <td>{{forms.facility_term_sheet.pre_factoring.tenure.label || '-'}} ({{forms.facility_term_sheet.pre_factoring.tenure.value || '-'}}) months
                      </td>
                    </tr>
                    <tr>
                      <td>Repayments:</td>
                      <td>Through <span class="text-lowercase">{{forms.facility_term_sheet.pre_factoring.tenure.label || '-'}}</span> ({{forms.facility_term_sheet.pre_factoring.tenure.value || '-'}}) fixed and equal monthly instalments of {{forms.currency}} {{convertCurrencyFormat(forms.facility_term_sheet.pre_factoring.repayments || '0') }}  each either by setoffs against factored contract proceeds or repayments by the Applicant.  

                      </td>
                    </tr> 
                    <tr>
                      <td>Instalment Due Dates:</td>
                      <td>{{forms.facility_term_sheet.pre_factoring.installment_due_dates.label || '-'}} ({{forms.facility_term_sheet.pre_factoring.installment_due_dates.value || '-'}}) month from date of Advance.


                      </td>
                    </tr>-->
                    <tr>
                      <td>Security and Credit Support:</td>
                      <td>
                        <ol class="list-group-numbered p-0 mb-0">
                          <li class="list-group-item mb-2" v-for="(value, index) in forms.security_and_credit_support.pre_factoring" :key="index" v-show="forms.security_and_credit_support.pre_factoring[index]">{{forms.security_and_credit_support.pre_factoring[index]}}</li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>Processing Fees:</td>
                      <td>
                        <div v-if="forms.facility_term_sheet" class="mb-2">
                        {{forms.currency}} {{ convertCurrencyFormat(forms.facility_term_sheet.pre_factoring.processing_fees || '0') }}
                        </div>
                        The fees do not include stamp duty to be charged under the Stamp Act, 1949.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end">
                <span class="text-dark" v-if="forms.pre_factoring_payee.length>3">6</span>
                <span class="text-dark" v-else>5</span>  | Page
              </div>
            </div>
          </div>
          <div class="printSection">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="bg-dark text-center text-white text-uppercase font-size-14 fw-bold">Risk Analysis Report</div>
              </div>
              <div class="col-auto ms-auto">
                <div class="bg-light text-center text-dark text-uppercase px-2 py-1 fw-semibold font-size-10">Document D</div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-auto" style="width: 120px;">
                <strong class="fw-medium text-dark">Identified Risks</strong>
              </div>
              <div class="col">
                <strong class="fw-medium text-dark">Risk Mitigation</strong>
              </div>
            </div>
            <div class="row mb-3" v-for="(value, index) in forms.risk_analysis_report" :key="index">
              <div class="col-auto" style="width: 120px;">
                {{ index+1 }}. {{value.type_of_identified_risks}}
              </div>
              <div class="col">
                <div class="d-flex align-items-center mb-2" v-for="(v, i) in value.risk_mitigation_list" :key="i">
                  <div class="me-2" v-if="v.title!==''">
                    <div class="d-flex text-center">
                      <div class="d-flex justify-content-center align-items-center w-100">
                        <div class="square_box" v-if="v.yesNo=='yes'">
                          <span>√</span>
                        </div>
                        <div v-if="v.yesNo=='no'" class="square_box"></div>
                      </div>
                    </div>
                  </div>
                  <div v-if="v.title!==''"><label class="fw-normal cursor-pointer mb-0" :for="index + 'w' + i">{{v.title}}</label></div>
                </div>
              </div>
            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end">
                <span class="text-dark" v-if="forms.pre_factoring_payee.length>3">
                  <span v-if="forms.facility_term_sheet.pre_factoring.enabled=='true'">7</span>
                  <span v-else>6</span>
                </span>
                <span class="text-dark" v-else>
                  <span v-if="forms.facility_term_sheet.pre_factoring.enabled=='true'">6</span>
                  <span v-else>5</span>
                </span>  | Page
              </div>
            </div>
          </div>
          <div class="printSection">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="bg-dark text-center text-white text-uppercase font-size-14 fw-bold">Justifications And Recommendations</div>
              </div>
              <div class="col-auto ms-auto">
                <div class="bg-light text-center text-dark text-uppercase px-2 py-1 fw-semibold font-size-10">Document E</div>
              </div>
            </div>
            <div class="row mb-0">
              <div class="col-12">
                <div class="sectionPart2 text-center fw-medium text-dark">Justifications</div>
              </div>
            </div>
            <div class="row mb-0 align-items-center" v-for="(value, index) in forms.justifications_and_recommendations.justifications.slice(0,5)" :key="index">
              <div class="col-auto pe-0 mb-1">
              <span v-if="index<5">{{ index+1 }}. </span>
              </div>
              <div class="col">
                <div class="d-flex align-items-center mb-1" v-if="index<5">
                  <div><label class="fw-normal cursor-pointer mb-0 lh-1" :for="index + 'Justifications'">{{value.title}}</label></div>
                  <div class="ms-auto mx-2">
                    <div class="d-flex text-center">
                      <div class="d-flex justify-content-center align-items-center w-100">
                        <div class="square_box" v-if="value.yesNo=='yes'">
                          <span>√</span>
                        </div>
                        <div v-if="value.yesNo=='no'" class="square_box"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-auto pe-0" v-if="forms.justifications_and_recommendations.justifications[5].title">
              <span>6.</span>
              </div>
              <div class="col">
                {{ forms.justifications_and_recommendations.justifications[5].title }}
              </div>
            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-auto pe-0" v-if="forms.justifications_and_recommendations.justifications[6].title">
              <span>7.</span>
              </div>
              <div class="col">
                {{ forms.justifications_and_recommendations.justifications[6].title }}
              </div>
            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-auto pe-0" v-if="forms.justifications_and_recommendations.justifications[7].title">
              <span>8.</span>
              </div>
              <div class="col">
                {{ forms.justifications_and_recommendations.justifications[7].title }}
              </div>
            </div>
            <div class="row mt-1 mb-0">
              <div class="col-12">
                <div class="sectionPart2 text-center fw-medium text-dark">Recommendations</div>
              </div>
            </div>
            <div class="row align-items-top mb-1" v-for="(value, index) in forms.justifications_and_recommendations.recommendations" :key="'recommendations'+index">
              <div class="col-auto pe-0" v-if="forms.justifications_and_recommendations.recommendations[index]">
              <span>{{ index+1 }}. </span>
              </div>
              <div class="col">
                <div class="d-flex align-items-center mb-1">
                {{forms.justifications_and_recommendations.recommendations[index]}}
                </div>
              </div>
            </div>
            <div class="row mb-0 mt-2">
              <div class="col-12 mb-0">
                <div class="sectionPart2 text-center fw-medium text-dark">Due Diligence Sign-Off</div>
              </div>
              <div class="col-6">
                <table class="w-100 text-start mb-0">
                  <tr>
                    <td width="80"></td>
                    <td height="80" class="text-center border-bottom border-dark pb-2">
                      <div v-if="forms.justifications_and_recommendations.due_diligence_sign_off_1">
                      <img v-if="forms.justifications_and_recommendations.due_diligence_sign_off_1.signature && forms.justifications_and_recommendations.due_diligence_sign_off_1_status=='signed'" :src="forms.justifications_and_recommendations.due_diligence_sign_off_1.signature" height="70" class="convert2dataurl">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Name:</td>
                    <td>
                      {{  (forms.justifications_and_recommendations.due_diligence_sign_off_1) ? forms.justifications_and_recommendations.due_diligence_sign_off_1.name : '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Position:</td>
                    <td>
                      {{  (forms.justifications_and_recommendations.due_diligence_sign_off_1) ? forms.justifications_and_recommendations.due_diligence_sign_off_1.position : '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td> {{forms.justifications_and_recommendations.due_diligence_sign_off_1_date || '-'}}</td>
                  </tr>
                </table>
              </div>
              <div class="col-6">
                <table class="w-100 text-start mb-2">
                  <tr>
                    <td width="80"></td>
                    <td height="80" class="text-center border-bottom border-dark pb-2">
                      <div v-if="forms.justifications_and_recommendations.due_diligence_sign_off_2">
                      <img v-if="forms.justifications_and_recommendations.due_diligence_sign_off_2.signature && forms.justifications_and_recommendations.due_diligence_sign_off_2_status=='signed'" :src="forms.justifications_and_recommendations.due_diligence_sign_off_2.signature" height="70" class="convert2dataurl">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Name:</td>
                    <td>
                      {{  (forms.justifications_and_recommendations.due_diligence_sign_off_2) ? forms.justifications_and_recommendations.due_diligence_sign_off_2.name : '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Position:</td>
                    <td>
                      {{  (forms.justifications_and_recommendations.due_diligence_sign_off_2) ? forms.justifications_and_recommendations.due_diligence_sign_off_2.position : '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td> {{forms.justifications_and_recommendations.due_diligence_sign_off_2_date || '-'}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 mb-0">
                <div class="sectionPart2 text-center fw-medium text-dark">Recommender</div>
              </div>
              <div class="col-6">
                <table class="w-100 text-start mb-0">
                  <tr>
                    <td width="80"></td>
                    <td height="80" class="text-center border-bottom border-dark pb-2">
                      <div v-if="forms.justifications_and_recommendations.recommender_1">
                      <img v-if="forms.justifications_and_recommendations.recommender_1.signature && forms.justifications_and_recommendations.recommender_1_status=='signed'" :src="forms.justifications_and_recommendations.recommender_1.signature" height="70" class="convert2dataurl">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Name:</td>
                    <td>
                      {{  (forms.justifications_and_recommendations.recommender_1) ? forms.justifications_and_recommendations.recommender_1.name : '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Position:</td>
                    <td>
                      {{  (forms.justifications_and_recommendations.recommender_1) ? forms.justifications_and_recommendations.recommender_1.position : '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td> {{forms.justifications_and_recommendations.recommender_1_date || '-'}}</td>
                  </tr>
                </table>
              </div>
              <div class="col-6">
                <table class="w-100 text-start mb-0">
                  <tr>
                    <td width="80"></td>
                    <td height="80" class="text-center border-bottom border-dark pb-2">
                      <div v-if="forms.justifications_and_recommendations.recommender_2">
                      <img v-if="forms.justifications_and_recommendations.recommender_2.signature && forms.justifications_and_recommendations.recommender_2_status=='signed'" :src="forms.justifications_and_recommendations.recommender_2.signature" height="70" class="convert2dataurl">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Name:</td>
                    <td>
                      {{  (forms.justifications_and_recommendations.recommender_2) ? forms.justifications_and_recommendations.recommender_2.name : '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Position:</td>
                    <td>
                      {{  (forms.justifications_and_recommendations.recommender_2) ? forms.justifications_and_recommendations.recommender_2.position : '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td> {{forms.justifications_and_recommendations.recommender_2_date || '-'}}</td>
                  </tr>
                </table>
              </div>

            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end">
                <span class="text-dark" v-if="forms.pre_factoring_payee.length>3">
                  <span v-if="forms.facility_term_sheet.pre_factoring.enabled=='true'">8</span>
                  <span v-else>7</span>
                </span>
                <span class="text-dark" v-else>
                  <span v-if="forms.facility_term_sheet.pre_factoring.enabled=='true'">7</span>
                  <span v-else>6</span>
                </span>  | Page
              </div>
            </div>
          </div>
          <div class="printSection">
            <div class="row justify-content-center align-items-center">
              <div class="col-auto text-center mb-3">
                <img src="@/assets/images/webapp/pdf-logo.png" height="90">
              </div>
              <div class="col text-center text-dark header mb-3">
                <h1 class="text-dark mb-0 fw-semibold">{{company_name}}
                <small class="fw-normal">{{company_registered_no}}</small></h1>
                <p class="mb-0" v-html="company_address"></p>
                <p class="mb-0">Tel: {{company_tel}}<br>Website: {{company_website}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="bg-dark text-center text-white text-uppercase font-size-14 fw-bold">Appendices</div>
              </div>
              <div class="col-auto ms-auto">
                <div class="bg-light text-center text-dark text-uppercase px-2 py-1 fw-semibold font-size-10">Document F</div>
              </div>
            </div>
            <div class="row mb-0 align-items-center" v-for="(value, index) in forms.appendix_list" :key="index">
              <div class="col-auto" style="width: 90px;" v-if="value.appendix_title">
                <span>{{value.appendix}} </span>
              </div>
              <div class="col" v-if="value.appendix_title">
                <div class="d-flex align-items-center mb-0">
                {{value.appendix_title}}
                </div>
              </div>
              <div class="col-12" v-if="value.appendix_title">
                <div class="py-2 bg-light"></div>
              </div>
            </div>
            <div class="position-absolute bottom-0 end-0 w-100 border-top border-light-2">
              <div class="text-end">
                <span class="text-dark" v-if="forms.pre_factoring_payee.length>3">
                  <span v-if="forms.facility_term_sheet.pre_factoring.enabled=='true'">9</span>
                  <span v-else>8</span>
                </span>
                <span class="text-dark" v-else>
                  <span v-if="forms.facility_term_sheet.pre_factoring.enabled=='true'">8</span>
                  <span v-else>7</span>
                </span>  | Page
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="mt-2 bg-light p-3 rounded">
          <div class="bg-white p-3 rounded shadow">
          <h4 class="font-size-14 mb-3 text-info">Applicant's Supporting Document </h4>
          <table class="table mb-0 btriped font-size-13" v-if="exportData.checkingFiles" :class="{'table-hover':exportData.checkingFiles.filter(e => e.file_name !== '').length>0 }">
            <thead>
              <tr>
                <th scope="col">Document Type</th>
                <th scope="col">Files</th>
              </tr>
            </thead>
            <tbody v-show="exportData.checkingFiles.filter(e => e.file_name !== '').length>0">
              <tr v-for="(value, index) in exportData.checkingFiles.filter(e => e.file_name !== '')" :key="index">
                <td>{{ value.name }}</td>
                <td><a :href="value.files" :title="value.file_name" target="_blank" class="text-orange">{{ value.file_name }}</a></td>
              </tr>
            </tbody>
            <tbody v-show="exportData.checkingFiles.filter(e => e.file_name !== '').length==0">
              <tr>
                <td class="text-center" colspan="2"><div class="my-3 text-muted">No Data Available</div></td>
              </tr>
            </tbody>
          </table>
      </div>
      </div>
      <div class="mt-2 bg-light p-3 rounded">
        <div class="bg-white p-3 rounded shadow">
          <div class="row">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">

              <h4 class="font-size-14 mb-1 text-info">{{ forms.approver_1.name }} </h4>
              <div class="mb-2"><i class="uil uil-comment-alt text-orange font-size-15"></i> <span class="font-size-13 text-orange fw-medium">Declined Comment</span></div>
              <div class="bg-light p-3 rounded-3">
                <p class="font-size-13 text-muted">{{ forms.approver_1_declined_comment || '-'}}</p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <h4 class="font-size-14 mb-1 text-info">{{ forms.approver_2.name }} </h4>
              <div class="mb-2"><i class="uil uil-comment-alt text-orange font-size-15"></i> <span class="font-size-13 text-orange fw-medium">Declined Comment</span></div>
              <div class="bg-light p-3 rounded-3">
                <p class="font-size-13 text-muted">{{ forms.approver_2_declined_comment || '-'}}</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      
      <div class="mt-1 bg-light p-3 rounded" v-if="forms.approver_1.username == accessUsername">
       <div class="bg-white p-3 rounded shadow">
          <h4 class="font-size-14 mb-2 text-info">{{ forms.approver_1.name }} - Declined Comment </h4>
          <div class="text-end">
            <textarea class="form-control mb-2" placeholder="Leave a comment here" rows="4" v-model.trim="forms.approver_1_declined_comment"> </textarea>
            <button
            class="btn btn-danger border-0"
            @click="isDeclinedLoading = true, declinedAndUpdate()"
            :disabled="isDeclinedLoading || loading"
          >
            <i class="uil uil-file-block-alt me-1"></i>
            <span v-if="isDeclinedLoading"> Declined and Updating...</span>
            <span v-else>  Declined and Update </span>
            <span class="spinner-border spinner-border-sm ms-2" v-if="isSignLoading" role="status" aria-hidden="true"></span>
          </button>
          </div>
         
        </div>
      </div>
      <div class="mt-1 bg-light p-3 rounded" v-if="forms.approver_2.username == accessUsername">
        <div class="bg-white p-3 rounded shadow">
          <h4 class="font-size-14 mb-2 text-info">{{ forms.approver_2.name }} - Declined Comment </h4>
          <div class="text-end">
            <textarea class="form-control mb-2" placeholder="Leave a comment here" rows="4" v-model.trim="forms.approver_2_declined_comment"> </textarea>
            <button
            class="btn btn-danger border-0"
            @click="isDeclinedLoading = true, declinedAndUpdate()"
            :disabled="isDeclinedLoading || loading"
          >
            <i class="uil uil-file-block-alt me-1"></i>
            <span v-if="isDeclinedLoading"> Declined and Updating...</span>
            <span v-else>  Declined and Update </span>
            <span class="spinner-border spinner-border-sm ms-2" v-if="isSignLoading" role="status" aria-hidden="true"></span>
          </button>
          </div>
          
        </div>
      </div>
      <template #modal-footer>
        <div>
        <div class="w-100">
      
          <button
            class="btn btn-primary btn-soft-primary border-0 ms-2"
            @click="$refs['modal-preview'].hide()"
          >
            <i class="uil uil-times"></i> Cancel
          </button>
          <button
            class="btn btn-primary border-0 ms-2"
            @click="isSignLoading = true, signAndSubmit()"
            v-if="checkUserAvailabilityToUpdateAndSign()"
            :disabled="isSignLoading || loading"
          >
            <i class="uil uil-file-check me-1"></i>
            <span v-if="isSignLoading"> Signing and Submitting...</span>
            <span v-else>  Sign and Submit </span>
            <span class="spinner-border spinner-border-sm ms-2" v-if="isSignLoading" role="status" aria-hidden="true"></span>
          </button>
          <button
            class="btn btn-primary border-0 ms-2"
            @click="generateLoading = true, generatePDF()"
            v-if="forms.applicant && forms.originator"
            :disabled="generateLoading || loading"
          >
            <i class="uil uil-file-export me-1"></i>
            <span v-if="generateLoading"> Generating...</span>
            <span v-else>  Generate PDF </span>
            <span class="spinner-border spinner-border-sm ms-2" v-if="generateLoading" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
      </template>
      
      
    </b-modal>
   
    <Common ref="commonFunc"/>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";
//import appConfig from "@/app.config";
import Common from "@/components/common";
//import axios from "axios";
//import Swal from "sweetalert2";
import {required, email, minLength } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== 'YOUR_API_ID';
}
export const equal2B = (value) => {
  return value !== 'YOUR_API_HASH';
}

export default {
  components: { 
    Common,
  },
  props: {
      exportData: {
        type: Object,
        required: true,
      }
  },
  watch: {
    exportData: {
      handler(newVal) {
        console.log('dataProp has changed:', newVal);
        this.update(newVal)
      },
      deep: true, // Watch for deep changes within the object
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  data() {
    return {
      id:"",
      prevRoute:{
        query:{
           status:""
        },
      },
      title: '',
      items: [],
      isSignLoading: false,
      isDeclinedLoading: false,
      forms: this.exportData.forms,
      formData:this.exportData.formData,
      checkingFiles: this.exportData.checkingFiles,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      account_type:'',
      showLottie:false,
      currency:'',
      company_name:'IKHLAS AL DAIN SDN BHD ',
      company_tel:'+603-7688 1014',
      company_website:'www.ikhlasaldain.com',
      company_address:'Lot 4.1, Level 4, Menara Lien Hoe, No.8 Persiaran Tropicana, Tropicana Golf & Country Resort,<br> 47410 Petaling Jaya',
      company_registered_no:'(201001018379/902085 X)',
      nature_of_contract: [
        {
          "label": "Recurring",
          "value": "recurring"
        },
        {
          "label": "One Off",
          "value": "one_off"
        },
        {
          "label": "Milestone/Staggered",
          "value": "milestone_staggered"
        },
        {
          "label": "Progress",
          "value": "progress"
        }
      ],
      pre_factoring_purpose_type:[
        "Term Advance",
        "Supplier Advance",
        "Bond Advance",
        "Cash Advance",
      ],
      factoring_purpose_type:[
        "Invoice Advance",
        "Collection Agent",
        "Letter of Support",
        "Letter of Undertaking"
      ],
      period:[30,60,90,120,150,180],
      collection_forecast:[
      {
        "label": "Thirty",
        "value": 30
      },
      {
        "label": "Forty Five",
        "value": 45
      },
      {
        "label": "Sixty",
        "value": 60
      },
      {
        "label": "Seventy Five",
        "value": 75
      },
      {
        "label": "Ninety",
        "value": 90
      },
      {
        "label": "One Hundred Twenty",
        "value": 120
      },
      {
        "label": "One Hundred Fifty",
        "value": 150
      },
      {
        "label": "One Hundred Eighty",
        "value": 180
      }
      ],
      tenure:[0,2,4,6,12,18,24],
      installment_due_dates: [
          {
            "label": "One",
            "value": 1
          },
          {
            "label": "Two",
            "value": 2
          },
          {
            "label": "Three",
            "value": 3
          },
          {
            "label": "Four",
            "value": 4
          },
          {
            "label": "Five",
            "value": 5
          },
          {
            "label": "Six",
            "value": 6
          },
          {
            "label": "Seven",
            "value": 7
          },
          {
            "label": "Eight",
            "value": 8
          },
          {
            "label": "Nine",
            "value": 9
          },
          {
            "label": "Ten",
            "value": 10
          },
          {
            "label": "Eleven",
            "value": 11
          },
          {
            "label": "Twelve",
            "value": 12
          },
          {
            "label": "Thirteen",
            "value": 13
          },
          {
            "label": "Fourteen",
            "value": 14
          },
          {
            "label": "Fifteen",
            "value": 15
          },
          {
            "label": "Sixteen",
            "value": 16
          },
          {
            "label": "Seventeen",
            "value": 17
          },
          {
            "label": "Eighteen",
            "value": 18
          },
          {
            "label": "Nineteen",
            "value": 19
          },
          {
            "label": "Twenty",
            "value": 20
          },
          {
            "label": "Twenty-One",
            "value": 21
          },
          {
            "label": "Twenty-Two",
            "value": 22
          },
          {
            "label": "Twenty-Three",
            "value": 23
          },
          {
            "label": "Twenty-Four",
            "value": 24
          },
          {
            "label": "Twenty-Five",
            "value": 25
          },
          {
            "label": "Twenty-Six",
            "value": 26
          },
          {
            "label": "Twenty-Seven",
            "value": 27
          },
          {
            "label": "Twenty-Eight",
            "value": 28
          },
          {
            "label": "Twenty-Nine",
            "value": 29
          },
          {
            "label": "Thirty",
            "value": 30
          },
          {
            "label": "Thirty-One",
            "value": 31
          }
      ],
      contracts_type: [
        {
          "name": "Federal government",
          "value": "federal_goverment"
        },
        {
          "name": "Non-federal government",
          "value": "non_federal_goverment"
        }
      ],
      payment_mode:[
        {
          name: 'ePerolehan',
          value: 'eP'
        }, 
        {
          name: 'Non-ePerolehan', 
          value: 'Non-eP'
        }
      ],
      typeEntity:[
        {"name":"Private Limited","value":"private_limited"},
        {"name":"Partnership","value":"partnership"},
        {"name":"Proprietorship","value":"proprietorship"}
      ],
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      generateLoading:false,
      create_modify_permission:false
    };
  },
  validations: {
    formData: {
      company_name: {
        required
      },
      business_registration_no: {
        required
      },
      date_of_registration: {
        required
      },
      type_of_entity: {
        required
      },
      business_address: {
        required
      },
      business_tel: {
        required
      },
      business_email: {
        required,
        email
      },
      contact_person: {
        required
      },
      position: {
        required
      },
      contact_tel: {
        required
      },
      contact_email: {
        required,
        email
      }
    },
    formData2: {
      banks: {
        required,
        minLength: minLength(1),
        $each: {
          bank_name: {
            required,
            minLength: minLength(2)
          },
          branch:{
            required,
            minLength: minLength(2)
          },
          account_number:{
            required,
            minLength: minLength(2)
          },
          facility:{
            required,
            minLength: minLength(2)
          },
          limit:{}
        }
      }
    },
    forms: {
      applicant: {
        required
      }
    }
  },
  middleware: "authentication",
  async mounted(){
    await this.reload()
    const elementsToConvert = document.querySelectorAll('.convert2dataurl');
      elementsToConvert.forEach((value) => {
        this.convertImageToDataUrl(value)
      });
  }, 
  created(){

  },
  methods:{
    updateLoading(value){
      this.isSignLoading = value
      this.isDeclinedLoading = value
    },
    
    showModal() {
        this.$refs['modal-preview'].show();
        setTimeout(() =>{
          //for delay find class convert2dataurl because too fast to render data.
          this.updateImageToDataURL();
        },0)
    },
    signAndSubmit(){
      this.$emit('callParentFunction');
    },
    declinedAndUpdate(){
      this.$emit('callParentFunction2');
    },
    checkUserAvailabilityToUpdateAndSign(){
      const usernames = [
        this.forms.approver_1.username|| '',
        this.forms.approver_2.username|| '',
        this.forms.justifications_and_recommendations.recommender_1.username || '',
        this.forms.justifications_and_recommendations.recommender_2.username || '',
        this.forms.justifications_and_recommendations.due_diligence_sign_off_1.username || '',
        this.forms.justifications_and_recommendations.due_diligence_sign_off_2.username || ''
      ];
      return usernames.includes(this.accessUsername);
    },
    resetModal(){
      console.log("reset")
    },
    update(newVal){
      this.forms =newVal.forms
      this.formData = newVal.formData
    },
    // Function to convert image URLs to data URLs
    convertImageToDataUrl(imageElement) {
      const src = imageElement.src;
      console.log(src);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function() {
          canvas.height = img.naturalHeight;
          canvas.width = img.naturalWidth;
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png'); // You can specify the desired output format
          // Set the data URL as the source for the image element
          imageElement.src = dataURL;
      };
      img.src = src;
    },
    async updateImageToDataURL(){
      console.log("update")
      const elementsToConvert = document.querySelectorAll('.convert2dataurl');
      elementsToConvert.forEach((value) => {
        console.log(value)
        this.convertImageToDataUrl(value)
      });
    },
    async generatePDF(){
    // Choose the element that our invoice is rendered in.
      const element = document.getElementById("nodeToRenderAsPDF");
      // clone the element
      const clonedElement = element.cloneNode(true);
      // change display of cloned element (if needed)
      clonedElement.style.display = "block";
      const options = {
        margin: 10,
        image: {
          type: 'jpeg',
          quality: 1
        },
        html2canvas: { 
          scale: 2, 
          width: 595,
          dpi: 72,
        },
        filename: 'credit_paper.pdf',
      };
      // Use html2pdf to generate the PDF
      //html2pdf().from(clonedElement).set(options).save();
      // Use html2pdf to generate the PDF
      html2pdf().from(clonedElement).set(options).save().then(() => {
        // Code to execute after PDF generation is finished
        // For example, set generateLoading to false
        this.generateLoading = false;

        // remove cloned element
        clonedElement.remove();
      });
      // html2pdf(document.getElementById("bootstrap-table"), {
      //   margin: 1,
      //   pagebreak: {
      //       mode: 'avoid-all',
      //       before: '.nextPage',
      //   },
      //   filename: "i-was-html.pdf",
      // })
     
    },
    convertCurrencyFormat(value,show00) {
      if (show00==true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      }else{
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          const username = await commonFunc.getUsername();
          this.accessUsername = username;
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='factoring_application')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "factoring_application" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>
<style scoped>
.min-width-760{
  min-width: 760px;
}
.partA tr td{
  min-width: 120px;
}
.partC tr td{
  padding: 8px 5px;
  border-color: #ddd!important;
}
.partB{
  min-width: 1000px;
}
.partB tr td{
  min-width: 120px;
}
.partB tr td textarea{
  min-width: 220px;
}
.partC{
  min-width: 800px;
}
.topArrow::before{
  width: 100%;
  display: block;
  left: 0px;
  text-align: center;
  top: -1px;
  font-size: 16px;
  content: '↑';
}
.printWrapper{
  font-family: 'Times New Roman', Times, serif !important;
  font-size: 11px !important;
  width:595px;
  background-color:#fff;
}
.printWrapper .header{
  font-size: 11px;
  line-height: 1.3;
}
.printWrapper .header h1{
  font-size: 22px;
}
.printWrapper .header h1 small{
  font-size: 9px;
}
.printSection{
  width:595px;
  height: 867px;
  color: #111 !important;
  position: relative !important;
}
.sectionPart{
  background-color: #e7e6e6;
  font-weight: 700;
  text-align: center;
  color: #222;
  padding: 1px 0px 2px 0px;
  border: 1px solid #222;
  margin-bottom: 5px;
}
.sectionPart2{
  background-color: #c0c0c0;
  font-weight: 700;
  text-align: center;
  color: #222;
  padding: 1px 0px 2px 0px;
  margin-bottom: 10px;
}
.printSection .text-dark{
  color: #222 !important;
}
.printSection .bg-dark{
  background-color: #000062 !important;
}
.printSection .bg-light{
  background-color: #e7e6e6 !important;
}
.customTable, .customTable2{
  width: 100%;
  margin-bottom: 9px;
}
.customTable tr td, .customTable tr th{
  color: #222;
  border-color: #222 !important;
  font-size: 10px !important;
  line-height: 1.3;
  padding: 2px 4px !important;
}
.customTable2 tr td, .customTable2 tr th{
  color: #222;
  border:1px solid #222 !important;
  border-right: none!important;
  border-bottom: none!important;
  font-size: 10px !important;
  line-height: 1.3;
}
.termsheet tr td, .termsheet tr th{
  padding: 4px 4px !important;
  vertical-align: top;
}
.termsheet table tr td{
  padding: 2px 4px !important;
}
.square_box{
  width: 18px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  border: 1px solid #111;
}
.topArrow::before{
  width: 100%;
  display: block;
  left: 0px;
  top: -1px;
  font-size: 12px;
  content: '↑';
  text-align: inherit;
}
</style>